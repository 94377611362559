import type { ComponentPropsWithRef } from 'react'

export default function Arrow(
  props: ComponentPropsWithRef<'svg'> & { alt?: string }
) {
  const { className, alt = 'Arrow' } = props
  return (
    <svg
      id="arrow"
      className={className || ''}
      viewBox="0 0 304 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <path
        d="M54 17C69.3472 84.0206 102.858 145.823 162.635 197.576C191.461 222.532 243.899 252 290 252"
        stroke="#EF5747"
        strokeWidth="16"
        strokeOpacity="0.90"
        strokeLinecap="round"
      />
      <path
        d="M50.4241 13.9533C31.9315 37.885 13.7542 71.482 13.7542 102.572"
        stroke="#EF5747"
        strokeWidth="27"
        strokeLinecap="round"
      />
      <path
        d="M51 14C98.9085 32.0934 146 57.4942 146 81"
        stroke="#EF5747"
        strokeWidth="27"
        strokeLinecap="round"
      />
    </svg>
  )
}
