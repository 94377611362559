import type { ExternalScriptsHandle } from 'remix-utils/external-scripts';

import { ChevronRightIcon } from '@heroicons/react/24/solid';
import type { LinksFunction, MetaFunction } from '@remix-run/node';
import { Link, useLoaderData } from '@remix-run/react';

import Footer from '~/components/Footer';
import { Header } from '~/components/Header';
import Arrow from '~/components/svg/arrow';
import Inbox from '~/components/svg/drawings/inbox';
import { getClientEnv, getSocialMetas, host } from '~/utils';

import { Waitlist } from '../products.remind.waitlist';

import Pricing from './Pricing';
import { problems } from './Problems';

import brandStyles from '~/styles/brand.css';

/**
 * @cached
 * https://dash.cloudflare.com/8ebebd185da1a949a4d2d75167f0038c/snipbot.com/rules/form
 */

export const links: LinksFunction = () => {
  return [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Fredoka+One&family=Montserrat+Alternates:ital,wght@0,500;0,700;0,800;1,400&display=swap',
    disabled: false
  },
  { rel: 'stylesheet', href: brandStyles },
  // styles for running Snipbot on Snipbot
  { rel: 'stylesheet', href: '/assets/snipbot.css' }
  //TODO: explore further https://github.com/whatwg/html/issues/6723
  // {
  //   rel: 'prefetch',
  //   as: 'iframe',
  //   href: 'https://www.snipbot.com/remind/forms/faac81f4-bb9a-4a27-a532-10f6baad21a8',
  // },
  ];
};

export const meta: MetaFunction = () => {
  return getSocialMetas({ url: `${host()}/` });
};

export let handle: ExternalScriptsHandle = {
  scripts: [
  {
    src: '/assets/snipbot.js',
    type: 'module',
    async: true
  },
  {
    src: 'https://challenges.cloudflare.com/turnstile/v0/api.js',
    async: true,
    defer: true
  }]

};

export function loader() {
  const h = host();
  const env = getClientEnv();
  return { host: h, ...env };
}

export default function Index() {
  const { host } = useLoaderData<typeof loader>();
  return (
    <div className="bg-brand-off-white">
      <Header />
      {/* <ClientOnly>
         {() => <GoogleAdsConversion tag="Joined Waitlist" />}
        </ClientOnly> */}
      {/* data-v-app is required to prevent hydration errors */}
      <div id="snipbot-modal" data-v-app="" />
      <main>
        {/* Hero section */}
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:pt-30 lg:pb-32 xl:pb-44">
          <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div>
                {/* <Link to="/blog/founding-customers">
                   <div className="inline-flex space-x-4">
                     <span className="rounded bg-orange-50 px-2.5 py-1 text-xs font-semibold text-orange-500 tracking-wide uppercase">
                       What's new
                     </span>
                     <span className="inline-flex items-center space-x-1 text-sm font-medium text-orange-500">
                       Founding Customer applications open
                       <ChevronRightIcon
                         className="w-5 h-5"
                         aria-hidden="true"
                       />
                     </span>
                   </div>
                  </Link> */}
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-extrabold tracking-tight font-cursive text-neutral-800 sm:text-5xl">
                    Privacy-focused email reminders
                  </h1>
                  <p className="mt-6 text-xl text-neutral-500">
                    Snipbot is an embeddable reminder-me-later notification
                    service for your website visitors.
                  </p>
                </div>
                <Waitlist placement="hero" />
                <div className="mt-6 text-center text-neutral-800">
                  <div>
                    <p>
                      Not the right time?{' '}
                      {/* This is one approach in React for ensuring that the button's onclick isn't handled by react and instead is immediately printed during SSR */}
                      <span
                        className="ml-2"
                        dangerouslySetInnerHTML={{
                          __html: `<a
                          href="${host}/remind/forms/faac81f4-bb9a-4a27-a532-10f6baad21a8?campaign=8abbdf0a-3f16-40f6-b5f9-ec0b69856c07&placement=c0f66727-9c8f-4bc0-86ea-30c9619587a6"
                          target="_blank"
                          data-snipbot
                          data-campaign="8abbdf0a-3f16-40f6-b5f9-ec0b69856c07"
                          data-placement="c0f66727-9c8f-4bc0-86ea-30c9619587a6"
                          data-form="faac81f4-bb9a-4a27-a532-10f6baad21a8" class="font-bold underline">Save for later</a>`
                        }} />

                      {/* This is the basic button for when the DOMContentLoaded event is triggering this button */}
                      {/* <button
                         id="snipbot-button"
                         className="ml-2 font-bold underline"
                        >
                         Save for later
                        </button> */}
                      <ChevronRightIcon className="inline-block w-6 h-6" />
                      <Arrow className="relative w-8 h-10 left-3/4" />
                    </p>
                    <p className="relative italic left-36 -rotate-12">Try me</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="hidden sm:block">
                <div className="absolute inset-y-0 w-screen left-1/2 bg-brand-off-white-contrast rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                <svg
                  className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392">

                  <defs>
                    <pattern
                      id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse">

                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-neutral-200"
                        fill="currentColor" />

                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={392}
                    fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />

                </svg>
              </div>
              <div className="relative pl-4 md:-mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12 xl:-mt-12">
                <Inbox className="max-w-4xl" />
              </div>
            </div>
          </div>
        </div>

        {/* Problem section */}
        <div className="py-16 overflow-hidden bg-brand-off-white lg:py-24">
          <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-7xl">
            <svg
              className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true">

              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">

                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-neutral-200"
                    fill="currentColor" />

                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />

            </svg>

            <div className="relative">
              <h2 className="text-3xl font-extrabold leading-8 text-neutral-900 md:text-center font-cursive sm:text-4xl">
                Building a more inclusive and consensual web
              </h2>

              <p className="max-w-3xl mx-auto mt-4 text-xl text-neutral-500 lg:text-2xl md:text-center">
                If you are looking for an app that will do{' '}
                <span className="font-semibold text-orange-500">anything</span>{' '}
                to make your numbers go up...
              </p>
              <p className="max-w-3xl mx-auto mt-4 text-xl font-bold text-right text-neutral-500 lg:text-2xl">
                ...we aren't the app for you.
              </p>
            </div>

            <div className="relative mt-20 lg:mt-32 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h3 className="text-3xl font-extrabold tracking-tight text-neutral-900 font-cursive sm:text-3xl">
                  The problem — why they don't convert
                </h3>

                <p className="mt-4 text-2xl text-neutral-500">
                  Why do you have so much <em>traffic</em> and{' '}
                  <em>engagement</em> but so few interactions with the{' '}
                  <span className="font-semibold text-orange-500">right</span>{' '}
                  people?
                </p>

                <dl className="mt-10 space-y-10">
                  {problems.map((item) =>
                  <div key={item.id} className="relative">
                      <dt>
                        <p className="text-lg font-semibold leading-6 text-neutral-900">
                          {item.name}
                        </p>
                      </dt>
                      <dd className="mt-2 text-lg text-neutral-500">
                        {item.description}
                      </dd>
                    </div>
                  )}
                </dl>
              </div>

              <div className="relative mt-10 -mx-4 lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
                  width={784}
                  height={404}
                  fill="none"
                  viewBox="0 0 784 404">

                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse">

                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-neutral-200"
                        fill="currentColor" />

                    </pattern>
                  </defs>
                  <rect
                    width={784}
                    height={404}
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />

                </svg>

                <img
                  className="relative mx-auto"
                  width={490}
                  src="/social.svg"
                  alt="Snipbot | Why your customers don't convert" />

              </div>
            </div>
          </div>
        </div>

        {/* Testimonial/stats section */}
        <div className="relative lg:mt-20">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div className="relative hidden sm:py-16 lg:py-0 lg:block">
              <div
                aria-hidden="true"
                className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">

                <div className="absolute inset-y-0 w-full right-1/2 bg-brand-off-white-contrast rounded-r-3xl lg:right-72" />
                <svg
                  className="absolute -ml-3 top-8 left-1/2 lg:-right-8 lg:left-auto lg:top-12"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392">

                  <defs>
                    <pattern
                      id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse">

                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-neutral-200"
                        fill="currentColor" />

                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={392}
                    fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />

                </svg>
              </div>
              <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                <div className="relative pb-60 pt-80">
                  <img
                    className="absolute inset-0 object-cover"
                    src="/who-is-it-for.svg"
                    alt="Snipbot | Who is it for?" />

                </div>
              </div>
            </div>

            <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
              {/* Content area */}
              <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2 className="text-3xl font-extrabold font-cursive text-neutral-800 sm:text-4xl">
                  Who is it for?
                </h2>
                <div className="mt-6 space-y-6 text-xl text-neutral-500">
                  <p className="first-letter:text-3xl first-letter:font-semibold">
                    Snipbot is for the marketer who{' '}
                    <span className="underline decoration-orange-200 decoration-2 underline-offset-4">
                      cares about marketing
                    </span>
                    .
                  </p>
                  <p className="first-letter:text-3xl first-letter:font-semibold">
                    The marketer who{' '}
                    <span className="underline decoration-orange-200 decoration-2 underline-offset-4">
                      sees themselves as a professional
                    </span>{' '}
                    , as someone who is worth more than their ability to run
                    Facebook ads, understand SEO, and create reports. It's for
                    the marketer who only makes{' '}
                    <span className="underline decoration-orange-200 decoration-2 underline-offset-4">
                      promises they can keep
                    </span>
                    .
                  </p>
                  <p className="first-letter:text-3xl first-letter:font-semibold">
                    Snipbot is for the development team who wants to enable
                    Marketing to{' '}
                    <span className="underline decoration-orange-200 decoration-2 underline-offset-4">
                      self-service
                    </span>{' '}
                    their campaigns without disrupting product development. They{' '}
                    <span className="underline decoration-orange-200 decoration-2 underline-offset-4">
                      care about performance
                    </span>{' '}
                    and are protective of spending development time{' '}
                    <em>(re)</em>writing marketing features <em>(again)</em>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Pricing />

        {/* CTA section */}
        <div className="relative mt-16 sm:mt-4 sm:py-16">
          <div aria-hidden="true" className="hidden sm:block">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-brand-off-white-contrast rounded-r-3xl" />
            <svg
              className="absolute -ml-3 top-8 left-1/2"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392">

              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">

                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-neutral-200"
                    fill="currentColor" />

                </pattern>
              </defs>
              <rect
                width={404}
                height={392}
                fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />

            </svg>
          </div>
          <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="relative px-6 py-10 overflow-hidden bg-orange-500 shadow-xl rounded-2xl sm:px-12 sm:py-20">
              <div
                aria-hidden="true"
                className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">

                <svg
                  className="absolute inset-0 w-full h-full"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 1463 360">

                  <path
                    className="text-orange-600 text-opacity-40"
                    fill="currentColor"
                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />

                  <path
                    className="text-orange-600 text-opacity-40"
                    fill="currentColor"
                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />

                </svg>
              </div>
              <div className="relative">
                <div className="sm:text-center">
                  <h2 className="text-3xl font-extrabold text-white font-cursive sm:text-4xl">
                    Get notified when we&rsquo;re launching.
                  </h2>
                  <p className="max-w-2xl mx-auto mt-6 text-lg text-orange-100">
                    We are currently interviewing, selecting, and onboarding our{' '}
                    <Link
                      to="/blog/founding-customers"
                      className="font-bold underline">

                      Founding Customers
                    </Link>
                    . You can join the waitlist below and drop us an extra note
                    if you wish to apply for a spot.
                  </p>
                </div>
                <Waitlist className="mx-auto" placement="footer" />
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>);

}