import {
  ClockIcon,
  ExclamationCircleIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'

export const problems = [
  {
    id: 1,
    name: 'Now is not a good time.',
    description:
      // eslint-disable-next-line prettier/prettier
      'We come to your website on our phones in the few spare moments of our day. Maybe we\'re getting off the bus, or we got a message from a friend. It\'s just not a good time to stick around.',
    icon: ClockIcon,
  },
  {
    id: 2,
    name: 'My email address has been abused.',
    description:
      // eslint-disable-next-line prettier/prettier
      'We don\'t trust that you won\'t spam us. It\'s happened too many times before. Trust must be earned. If you haven\'t proven yourself yet, now is your chance.',
    icon: ExclamationCircleIcon,
  },
  {
    id: 3,
    name: 'If an interruption brought us here, the next interruption will take us somewhere else.',
    description:
      // eslint-disable-next-line prettier/prettier
      'Let\'s face it, if your advertising disrupted my time on social media to bring me to your website and I choose to leave, your competitors will do the same to me tomorrow.',
    icon: MegaphoneIcon,
  },
]
