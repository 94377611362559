import { CheckIcon } from '@heroicons/react/24/outline'

const tiers = [
  {
    name: 'Starter',
    href: '#',
    priceMonthly: 45,
    description:
      'Get started with a single user account targeting your most valuable conversion funnels.',
    features: [
      '3 active campaigns',
      'Conversion tracking',
      'Up to 10,000 monthly email reminders',
      'Branded reminder forms',
    ],
  },
  {
    name: 'Team',
    href: '#',
    priceMonthly: 250,
    description:
      'Designed for high-performance teams with multiple users and conversion funnels.',
    features: [
      '...all starter features, plus',
      'Multiple user access',
      'Unlimited campaigns',
      'Campaign scheduling',
      'Do-it-for-you account setup',
      'Do-it-for-you initial campaign setup',
      'Send emails from your own domain',
      'Load snipbot from your own domain',
    ],
  },
]

export default function Pricing() {
  const signupEnabled = false
  return (
    <div className="relative mt-20">
      <div className="brand-off-white-contrast0">
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 className="hidden">Pricing</h2>
              <p className="text-3xl font-extrabold text-neutral-800 font-cursive sm:text-4xl lg:text-5xl">
                The right price for your team, big and small
              </p>
              <p className="pt-3 text-xl text-neutral-500">
                Snipbot handles all the hard parts of running a thoughtful and
                privacy-compliant marketing campaign.
              </p>
            </div>
          </div>
        </div>
        <div className="pb-12 mt-8 bg-brand-off-white-contrast sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 bg-brand-off-white-contrast h-3/4" />
            <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                {tiers.map((tier) => (
                  <div
                    key={tier.name}
                    className="flex flex-col overflow-hidden rounded-lg shadow-lg"
                  >
                    <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                      <div>
                        <h3
                          className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide text-orange-600 uppercase bg-orange-100 rounded-full"
                          id="tier-standard"
                        >
                          {tier.name}
                        </h3>
                      </div>
                      <div className="flex items-baseline mt-4 text-6xl font-extrabold">
                        ${tier.priceMonthly}
                        <span className="ml-1 text-2xl font-medium text-gray-500">
                          /mo
                        </span>
                      </div>
                      <p className="mt-5 text-lg text-gray-500">
                        {tier.description}
                      </p>
                    </div>
                    <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-neutral-50 sm:p-10 sm:pt-6">
                      <ul role="list" className="space-y-4">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              {!feature.startsWith('...') && (
                                <CheckIcon
                                  className="w-6 h-6 text-green-500"
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      {signupEnabled ? (
                        <div className="rounded-md shadow">
                          <a
                            href={tier.href}
                            className="flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-gray-800 border border-transparent rounded-md hover:bg-gray-900"
                            aria-describedby="tier-standard"
                          >
                            Get started
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="relative px-4 mx-auto mt-4 max-w-7xl sm:px-6 lg:px-8 lg:mt-5">
            <div className="max-w-md mx-auto lg:max-w-5xl">
              <div className="px-6 py-8 bg-white rounded-lg sm:p-10 lg:flex lg:items-center">
                <div className="flex-1">
                  <div>
                    <h3 className="inline-flex py-1 text-sm font-semibold tracking-wide text-gray-800 uppercase bg-white rounded-full">
                      Agencies
                    </h3>
                  </div>
                  <div className="mt-4 text-lg text-gray-600">
                    Access multiple clients using the same login, access roles
                    for your account teams, unlimited campaigns, CPA or
                    affiliate billing models on a per-account basis, GTM setup
                    for each client.
                    <p className="mt-2 text-base font-semibold text-gray-900">
                      Minimum $1000 monthly spend
                    </p>
                  </div>
                </div>
                {signupEnabled ? (
                  <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                    <a
                      href="#"
                      className="flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-gray-200 border border-transparent rounded-md hover:bg-gray-50"
                    >
                      Buy Discounted License
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
